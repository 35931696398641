import React from 'react';
import { Accordion, Card, Carousel, Col, Collapse, Image, ResponsiveEmbed, Row } from 'react-bootstrap';
import { projects } from './data/projects';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

class Projects extends React.Component {
  constructor(props) {
    super(props);
    
    let tags = [];
  
    for (let i = 0; i < projects.length; i++) {
      for (let j = 0; j < projects[i].tags.length; j++) {
        const tagName = projects[i].tags[j];

        if (tags.filter(tag => tag.name === tagName).length === 0) {
          tags.push({
            name: tagName,
            visible: true,
            colour: 0
          });
        }
      }
    }

    tags.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    for (let i = 0; i < tags.length; i++) {
      tags[i].colour = i % 10 + 1;
    }

    this.state = {
      tags: tags.slice(0),
      allTagsVisible: true,
      portfolioRef: props.portfolio,
      lastProjectOffsetTop: 0,
      lastProjectBodyHeight: 0
    };
  }

  ToggleFilterTag(tag) {
    let tags = this.state.tags.slice(0);
    const tagIndex = tags.findIndex(t => t.name === tag);

    let allTagsVisible = tags.reduce((out, t) => {
      return out && t.visible;
    }, true);

    if (allTagsVisible || (!allTagsVisible && !tags[tagIndex].visible)) {
      tags.forEach((t) => {
        t.visible = false;
      });

      tags[tagIndex].visible = !tags[tagIndex].visible;

      allTagsVisible = false;
    } else if (!allTagsVisible && tags[tagIndex].visible) {
      tags.forEach((t) => {
        t.visible = true;
      });

      allTagsVisible = true;
    }

    this.setState({
      tags: tags.slice(0),
      allTagsVisible: allTagsVisible,
      currentEventKey: null,
      lastProjectOffsetTop: 0,
      lastProjectBodyHeight: 0
    });
  }

  ClearFilters() {
    let tags = this.state.tags.slice(0);

    tags.forEach((t) => {
      t.visible = true;
    });

    this.setState({
      tags: tags.slice(0),
      allTagsVisible: true,
      currentEventKey: null
    })
  }
  
  Tag(tag, alwaysVisible = false) {
    return (
      <button className={`tag tag${tag.colour} tag-${tag.visible || alwaysVisible}`} onClick={() => this.ToggleFilterTag(tag.name)}>
        {tag.name}
      </button>
    );
  }

  FilterTags() {
    return (
      <div className="filter-tags">
        {this.state.tags.map((tag, index) => 
          <span key={index}>
            {' '}
            {this.Tag(tag)}
          </span>
        )}
        {' '}
        {!this.state.allTagsVisible &&
          <button className={`tag tag-clear`} onClick={() => this.ClearFilters()}>
            x Clear Filters
          </button>
        }
      </div>
    );
  }

  ImageCarousel(project) {
    const displayImageVideo = (image) => {
      if (image.videopath) {
        return (
          <ResponsiveEmbed aspectRatio="16by9">
            <iframe src={image.videopath} title={image.title} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </ResponsiveEmbed>
        );
      } else {
        return (
          <div className="preview">
            <div className="preview-inner">
              <a href={image.link ? image.link : image.path} target="new"><img className="preview-image" src={image.path} alt={image.alt} /></a>
            </div>
          </div>
        );
      }
    }

    if (project.images.length === 1) {
      const image = project.images[0];
  
      return (
        <div className="preview-item">
          {displayImageVideo(image)}
        </div>
      );
    } else if (project.images.length > 1) {
      return (
        <Carousel interval={null}>
          {project.images.map((image, index) => 
            <Carousel.Item key={index}>
            {displayImageVideo(image)}
            </Carousel.Item>
          )}
        </Carousel>
      );
    } else {
      return null;
    }
  }
  
  ProjectTags(tags) {
    let t = tags;
    t.sort();
  
    return (
      <div className="project-tags">
        {t.map((tag, index) => 
          <span key={index}>
            {' '}
            {this.Tag(this.state.tags.find(t => t.name === tag), true)}
          </span>
        )}
      </div>
    );
  }

  Project(project, index) {
    let visible = true;
    const headerDiv = React.createRef();
    const bodyDiv = React.createRef();

    if (!this.state.allTagsVisible) {
      const visibleTags = this.state.tags.filter(tag => tag.visible);

      visibleTags.forEach((tag) => {
        visible = visible && project.tags.find(t => t === tag.name) !== undefined;
      });
    }

    const scrollToHeader = () => {
      const divOffsetTop = headerDiv.current.offsetTop;

      if (divOffsetTop <= this.state.lastProjectOffsetTop || this.state.lastProjectOffsetTop === 0 || this.state.lastProjectBodyHeight === 0) {
        window.scrollTo({
          top: divOffsetTop,
          left: 0,
          behavior: "smooth"
        });
      } else {
        window.scrollTo({
          top: divOffsetTop - this.state.lastProjectBodyHeight,
          left: 0,
          behavior: "smooth"
        });
      }
    };

    const recordDivHeight = () => {
      const divOffsetTop = headerDiv.current.offsetTop;
      const bodyHeight = bodyDiv.current.clientHeight;

      this.setState({
        lastProjectOffsetTop: divOffsetTop,
        lastProjectBodyHeight: bodyHeight
      });
    };

    const resetDivPositions = () => {
      if (this.state.currentEventKey === null) {
        this.setState({
          lastProjectOffsetTop: 0,
          lastProjectBodyHeight: 0
        })
      }
    };

    return (
      <Collapse key={project.id} in={visible}>
        <div>
          <Accordion.Toggle as={Card.Header} eventKey={index+1}>
            <h2 className="project-title" ref={headerDiv}>{project.title}</h2>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={index+1} onEnter={scrollToHeader} onEntered={recordDivHeight} onExited={resetDivPositions}>
            <div ref={bodyDiv}>
              <div className="spacer"></div>
              {this.ImageCarousel(project)}
              {project.body}
              <div className="spacer"></div>
              {this.ProjectTags(project.tags)}
              <div className="up" onClick={() => this.state.portfolioRef.current.scrollIntoView({ behavior: "smooth" })}>
                <img className="up-arrow" src="/images/up-arrow.png" alt="Go back up" />
              </div>
            </div>
          </Accordion.Collapse>
          <div className="spacer"></div>
          <hr />
        </div>
      </Collapse>
    );
  }

  SetEventKey(eventKey) {
    this.setState({
      currentEventKey: eventKey
    });
  }

  render() {
    return (
      <div>
        {this.FilterTags()}
        <div className="spacer"></div>
        <hr />
        <Accordion activeKey={this.state.currentEventKey} onSelect={(eventKey, e) => this.SetEventKey(eventKey)}>
          {projects.map((p, index) => 
            this.Project(p, index)
          )}
        </Accordion>
      </div>
    );
  }
}

function App() {
  const portfolioRef = React.createRef();

  return (
    <Row>
      <Col lg={3}></Col>
      <Col className="content" xs={12} lg={6}>
        <h1 className="title">Nick Chiang</h1>
        <hr className="title-hr" />
        <div className="subtitle">
          <p>UX Engineer / Prototyper / Designer</p>
        </div>
        <h1 className="page-section" ref={portfolioRef}>Portfolio</h1>
        <Projects portfolio={portfolioRef} />
        <h1 className="page-section">About</h1>
        <Row noGutters={true}>
          <Col xl={7}>
            <p>Hi, I'm Nick.</p>

            <p>I'm an UX focused professional, having had roles as a Lead UX Designer, Program Manager, Producer and Software Engineer. Currently, I'm a Senior Frontend Engineer at Amazon Alexa, helping give Alexa more personality through adaptive responses. I specialize in breaking down complex UX problems, with an emphasis on working through user experience problems across hardware and software.</p>

            <p>I love diving deep into HCI problems and building out prototypes to test hypothesis. My experiences include working on multitouch and stylus interactions, VR and AR experiences, and voice interactions.</p>

            <p>
              <a href="http://www.nickchiang.com/Chiang,%20Nick%20-%20Resume.pdf">Resume</a>{' / '}
              <a href="mailto:nick.chiang@gmail.com">Email</a>{' / '}
              <a href="https://www.linkedin.com/in/nickchiang">LinkedIn</a>{' / '}
              <a href="https://github.com/nichiang">GitHub</a>
            </p>
          </Col>
          <Col xl={1}></Col>
          <Col xl={4}>
            <Image className="profile-image" src="/images/profile3.jpg" alt="profile" roundedCircle />
          </Col>
        </Row>
        <div className="spacer"></div>
        <div className="footer">&#169;2022 Nick Chiang</div>
      </Col>
      <Col lg={3}></Col>
    </Row>
  );
}
    
export default App;
    