import React from 'react';

export const projects = [
  {
    "id": "dredd",
    "title": "Dredd: Voice Controls in a First Person Shooter",
    "tags": [
      "vr",
      "unreal engine",
      "personal",
      "voice"
    ],
    "body": (
      <div>
        <p>I was inspired by the voice activated gun from the Judge Dredd comics, and the Dredd (2012) movie in particular, to explore how that might translate into a first person shooter experience. I built the demo using Unreal Engine 4, with the voice control powered by a custom Alexa skill.</p>
        <p>The game and the skill both connect as clients to a local server using <a href="https://socket.io/" target="new">Socket.io</a>, a real-time communication channel built on top of websockets. The Alexa skill uses the Alexa Web API for Games, which hosts a web app as the visual frontend for the voice skill. By having everything running locally on my PC (the game, the socket server and the HTTP server for the web app), the latency for microphone open requests from the game to the Alexa skill were kept to a minimum, allowing the Echo device to seamlessly open the microphone when triggered by something in-game.</p>
        <p>In the demo video, during the PC gameplay sequence, the microphone open was triggered simply via a keystroke. In the VR experience, the microphone is opened when the gun is brought close to the player's HMD. While in the demo, it uses a simple collision between a collider on the controller and one on the headset, additional tuning could be done here the prevent accidental activations (for example, requiring the user to hold up the side of the gun to the face by using a line trace).</p>
        <p>The rest of the game was built up in order to test how well the voice interaction works when used in tense situations. The enemy AI was custom built using Behavior Trees.</p>
        <p>Lawgiver model by <a href="https://www.blendswap.com/blend/22783" target="new">panko10</a></p>
      </div>
    ),
    "images": [
      {
        "videopath": "https://www.youtube-nocookie.com/embed/PVKNwcikBEc",
        "title": "Dredd, An Alexa Voice Integration with Unreal Engine Demo"
      }
    ]
  },
  {
    "id": "wordpop",
    "title": "Word With Friends Word Pop",
    "tags": [
      "voice",
      "html"
    ],
    "body": (
      <div>
        <p>Words With Friends: Word Pop is an Alexa game based on the popular Words With Friends mobile games. In partnership with Zynga, I designed and built the visual frontend as a web app using the Alexa Web API for Games.</p>
        <p>The web app uses the <a href="https://www.pixijs.com/" target="new">Pixi.js</a> library for rendering out to WebGL. All of the game logic was controlled by the Alexa skill backend. The skill needed to work on both multimodal and voice-only devices, so I developed a "playlist" style command structure that allowed the skill to build up the appropriate response depending on the type of device it's being played on.</p>
        <p><a href="https://www.amazon.com/Words-with-Friends-Word-Pop/dp/B0882YCYH3/" target="new">Word Pop</a> is available to play for free on Alexa enabled devices, just say "Alexa, play word pop".</p>
      </div>
    ),
    "images": [
      {
        "videopath": "https://www.youtube-nocookie.com/embed/aZoBQ1YWaNs",
        "title": "Word Pop"
      }
    ]
  },
  {
    "id": "jaunt-vr",
    "title": "Jaunt VR App",
    "tags": [
      "vr",
      "unity"
    ],
    "body": (
      <div>
        <p>
          The Jaunt VR app is a library for 360 video content, designed to work on a range of VR devices from smartphones with Google Cardboard, to Oculus Rift and HTC Vive desktop HMDs.
        </p>
        <p>
          There were numerous challenges to overcome when we designed this app from the ground up - a low minimum baseline device with no button input, a lack of existing design patterns that users understand, the need to create an experience approachable by users new to the medium of VR. Tackling this involved much experimentation and prototyping, during which we also developed many new VR focused prototyping tools and processes (mostly around Unity). We went through multiple iterations and rounds of user testing to arrive at the experience that was shipped to consumers.
        </p>
        <h3>Issues and Requirements</h3>
        <ul>
          <li>No guarantee of a trigger for user input - Not having a button and relying solely on the user's gaze became the baseline target for the experience</li>
          <li>No standard control paradigms - All standard controls found on web, mobile and desktop experiences rely on having a keyboard, mouse or touchscreen to signal the user's intent</li>
          <li>No standards for visual hierarchy in an immersive 3D space - There's no longer an 'edge of the screen' that can be relied on to visually anchor content and controls to</li>
        </ul>
        <h3>Approach</h3>
        <ul>
          <li>Designed a gaze based button that triggers after looking at it for a set amount of time, signaling user intent</li>
          <li>On content thumbnails, a two-state hover mechanism surfaces a separate selection button to avoid accidental selection issues</li>
          <li>Designed a mechanism for activating playback controls in a 360 video that takes up the entire view</li>
          <li>Developed a visual hierarchy for laying out content and controls - Global navigation gets anchored towards the ground (less neck strain than above), while content gets anchored around the horizon</li>
          <li>Developed a set of guidelines for 3D asset placement, focused on keeping content readable in HMD, visible within different FOVs of different headsets, and ensuring the objects were comfortably positioned and integrated with the background environment</li>
          <li>Created hardware input mappings that correspond to platform defaults - Ex. tapping on the trackpad for selection on the GearVR versus pulling the trigger on a Vive controller</li>
          <li>Designed a toggleable VR/2D mode for smartphones users without a Google Cardboard</li>
        </ul>
      </div>
    ),
    "images": [
      {
        "path": "images/jaunt_vr_app/image0.jpg",
        "alt": "The initial Home world that users arrive at."
      },
      {
        "path": "images/jaunt_vr_app/image1.jpg",
        "alt": "The gaze timer mechanism on a button."
      },
      {
        "path": "images/jaunt_vr_app/image2.jpg",
        "alt": "The playback controls trigger button following the user's view."
      },
      {
        "path": "images/jaunt_vr_app/image3.jpg",
        "alt": "The playback controls when activated."
      },
      {
        "path": "images/jaunt_vr_app/image4.jpg",
        "alt": "The 2D mode view of the app for smartphone users without a Google Cardboard."
      }
    ]
  },
  {
    "id": "lsx-guidelines",
    "title": "Large Screen UX Guidelines",
    "tags": [
      "multitouch",
      "surface hub"
    ],
    "body": (
      <div>
        <p>
          Large scale Surface Hub displays come with their own set of UX challenges and restrictions that aren't evidently when developing applications on laptop and desktop sized touch devices. On the Large Screen Experience team at Microsoft, the recognition of these issues led me to create a set of UX guidelines for apps that target large scale displays.
        </p>
        <p>
          Topics covered in the document include the different scenarios for using large scale displays, human factors, controls placement, the use of colour and animation.
        </p>
      </div>
    ),
    "images": [
      {
        "path": "images/ux_guidelines/image0.jpg",
        "alt": "Designing Windows Store Apps for Perceptive Pixel Displays",
        "link": "https://www.nickchiang.com/Designing_apps_for_PPI_displays.pdf"
      }
    ]
  },
  {
    "id": "lsx-nav",
    "title": "Infinite Canvas Navigation",
    "tags": [
      "multitouch",
      "surface hub"
    ],
    "body": (
      <div>
        <p>
          Digital whiteboards often espouse the benefit of an infinite canvas. However, with unconfined pan and touch capabilities, and a lack of solid points of reference, users can easily become lost within the space. This problem is compounded when the experience is run on a large scale display.
        </p>
        <p>
          This UX prototype explored ways to provide subtle navigation bounds and visual feedback to address this issue.
        </p>
        <h3>Issues and Requirements</h3>
        <ul>
          <li>Targeting large scale Perceptive Pixel (stylus and multi-touch enabled) displays</li>
          <li>Enable the benefit of an infinite canvas while subtly restricting the experience so the user doesn't get lost</li>
          <li>Mini-maps and similar positional references don't work as well on large scale displays - mini-maps placed on the corners of the canvas space will fall beyond the user's FOV on a large scale display. The same issue applies for zoom controls.</li>
          <li>Physical movement across a large scale display can be tiring. Leverage the ability for users to pull content towards themselves instead of having to walk, stretch or bend down to reach the content</li>
          <li>Without consistent zoom indicators, a user can unintentionally write text at varying</li>
        </ul>
        <h3>Approach</h3>
        <ul>
          <li>Use bounded panning to keep at least a small portion of the user's content visible on the screen</li>
          <li>On screen zoom indicators, and snapped zoom levels, to give feedback of zoom state</li>
          <li>The ability to pull the canvas in all directions to bring the content to a comfortable writing level for the user</li>
        </ul>
      </div>
    ),
    "images": [
      {
        "videopath": "https://www.youtube-nocookie.com/embed/dctMc0T7dek",
        "title": "Canvas touch zoom/pan prototype"
      }
    ]
  },
  {
    "id": "lsx-hover",
    "title": "Touch Hover Exploration",
    "tags": [
      "multitouch",
      "personal"
    ],
    "body": (
      <div>
        <p>
          Touch points on touchscreens can be viewed as an inherently single bit operation - the touch is either on the screen or not. At the same time, gestural controls can feel too freeform due to its lack of haptic feedback.
        </p>
        <p>
          This UX prototype explored the use of a Leap Motion controller to bridge the gap between gestural and touch controls, enabling a hover state for touch.
        </p>
        <h3>Issues and Requirements</h3>
        <ul>
          <li>Touch screens do not provide hover feedback for touch points</li>
          <li>Gestural controls are too freeform</li>
        </ul>
        <h3>Approach</h3>
        <ul>
          <li>Used Leap Motion SDK to provide hover feedback for touch points</li>
          <li>Hover for more information - This is a common, accepted gesture when using mouse controls, but there is no analog for this when using touch</li>
          <li>Palm detection for stylus scenarios - By leveraging the Leap Motion's capability to see tools, palm rejection can be performed in a more intelligent way, leading to a more natural stylus experience (avoiding hacks like disabling all touch when a stylus is in hover range</li>
        </ul>
      </div>
    ),
    "images": [
      {
        "videopath": "https://www.youtube-nocookie.com/embed/5FcQw4cDi7I",
        "title": "Touch and gestures test"
      }
    ]
  },
  {
    "id": "lsx-adaptive",
    "title": "Large Screen Adaptive UX",
    "tags": [
      "multitouch",
      "surface hub"
    ],
    "body": (
      <div>
        <p>
          Content creation on large scale displays can often be encumbered by the poor placement of controls. Having to bend, stretch or walk over to reach traditional tool bar and palette placements can be physically tiring after a few minutes of usage. Furthermore, really large scale displays (80+) can accommodate multiple simultaneous users, which can break down traditional controls intended for a single user.
        </p>
        <p>
          This UX prototype explored leveraging a Kinect 2.0 to address these issues, providing user tracking for intelligent contextual controls placement.
        </p>
        <h3>Issues and Requirements</h3>
        <ul>
          <li>Targeting large scale Perceptive Pixel (stylus and multi-touch enabled) displays</li>
          <li>Traditional control placements in toolbars and palettes can be difficult or tiring to repeatedly reach and use</li>
          <li>A singular set of content creation controls breaks down for multiple simultaneous user scenarios</li>
        </ul>
        <h3>Approach</h3>
        <ul>
          <li>Prototype basis was a whiteboard content creation app</li>
          <li>Use Kinect 2.0 skeletal SDK to track the physical location of users</li>
          <li>Intelligently place contextual controls within the reach of users based on their skeletal location, while taking care to avoid placement in the area they're actively writing/drawing on</li>
          <li>Explored further contextual tools that replicate physical tools - touch and stylus enabled rulers for straight edges</li>
          <li>Proposed use of Kinect skeletal ID to present multiple simultaneous contextual controls, bind skeletal ID to inputs</li>
        </ul>
      </div>
    ),
    "images": []
  },
  {
    "id": "treehouse",
    "title": "Treehouse Web App",
    "tags": [
      "mobile"
    ],
    "body": (
      <div>
        <p>
          The Treehouse web app was developed as part of hackathon for the non-profit <a href="https://www.treehouseforkids.org/">Treehouse</a>, helping to support and guide foster kids up to adulthood. The primary purpose of the web app is to provide some basic goal tracking for the student, and to be a readily available interface for students to communicate with their Treehouse specialist.
        </p>
        <h3>Issues and Requirements</h3>
        <ul>
          <li>There was a pre-existing web interface that supported their scenarios. It had some responsive elements, but was not completely suited to mobile use.</li>
          <li>Desire for notifications on the student's mobile device to update them on goal tracking or new messages.</li>
        </ul>
        <h3>Approach</h3>
        <ul>
          <li>Created a new design mock adapted from the existing web interface, but targeted at native mobile use. It was scoped for ease of implementation, not deviating too far from the original flow.</li>
          <li>The existing web interface was turned into a Progressive Web App to access OS level notifications.</li>
        </ul>
      </div>
    ),
    "images": [
      {
        "path": "images/mobile_treehouse/image0.png",
        "alt": "Mobile focused design mock and flow for the Treehouse web app."
      }
    ]
  },
  {
    "id": "jaunt-mobile",
    "title": "Jaunt Player App",
    "tags": [
      "mobile",
      "vr"
    ],
    "body": (
      <div>
        <p>
          The Jaunt Player app is a 360 degree video app designed for iOS and Cardboard. It presents users with a simple 2D interface for selecting a piece of content to watch, then prompts users to view the content within a Cardboard VR headset.
        </p>
        <h3>Issues and Requirements</h3>
        <ul>
          <li>Allow content managers to highlight newly added and featured content on the main screen.</li>
          <li>The majority of users of the app are new to VR and Cardboard headsets, and need guidance through the experience.</li>
        </ul>
        <h3>Approach</h3>
        <ul>
          <li>Server configurable layout hierarchy and badging system.</li>
          <li>Individual video preview pages that give users more information about the video they're about to watch, as well as the ability to share out to social media.</li>
          <li>Multiple points of guidance for users new to VR - information page with details on acquiring and using a Cardboard headset, guided prompts prior to watching videos to give time for users to get their Cardboard headsets setup.</li>
          <li>Quick turnaround time - app was designed, written and shipped to the Apple app store in the span of 2 weeks.</li>
        </ul>
      </div>
    ),
    "images": [
      {
        "path": "images/mobile_jaunt_app/image0.jpg",
        "alt": "Main screen, featuring a variable content hierarchy, and a link to the information page."
      },
      {
        "path": "images/mobile_jaunt_app/image1.jpg",
        "alt": "Content preview screen, with social share link."
      }
    ]
  },
  {
    "id": "ping",
    "title": "Ping App Concept",
    "tags": [
      "mobile",
      "personal"
    ],
    "body": (
      <div>
        <p>
          When going on a trip, friends and loved ones will often like to keep tabs on one's progress, particularly if travelling alone. Inspired by my friend's solo motorcycle trip across North America, the goal of the Ping app is to provide a means for users to easily inform their friends and loved ones of their whereabouts through social media.
        </p>
        <h3>Issues and Requirements</h3>
        <ul>
          <li>Friends and loved ones want to keep tabs on a user's progress on a trip</li>
          <li>It can be easy to forget to, and sometimes inconvenient, to manually check-in on social media to report one's progress</li>
          <li>Travelling through low cellular reception areas can make it difficult to check-in via a data connection</li>
        </ul>
        <h3>Approach</h3>
        <ul>
          <li>The Ping app provides an easy means to send updates to social media (i.e. Facebook, Twitter)</li>
          <li>Easy, single tap experience to manually report a user's location</li>
          <li>Ability to setup automatic timed posts to social media, reporting the user's position</li>
          <li>In areas where cellular reception is too low for a data connection, use SMS under the covers to report the user's position</li>
        </ul>
      </div>
    ),
    "images": [
      {
        "videopath": "https://www.youtube-nocookie.com/embed/FkyfGLSEqU4",
        "title": "Ping app animation study"
      }
    ]
  }
];
